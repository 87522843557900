
const initialState = {};

const reducer = (state = initialState, action) => {
  if (action.payload && action.payload.entities) {
    debugger;
    return state;
  }

  return state;
};


export default reducer;
