import React from 'react';
import classNames from 'classnames';


interface Props {
  children: string | React.ReactNode;
  isError?: boolean;
  isUnderlined?: boolean;
}

const FormGroupMessage: React.FC<Props> = ({ children, isError, isUnderlined }) => {
  const className = classNames('aen-formgroup__message', {
    [`aen-formgroup__message--error`]: isError,
    [`aen-formgroup__message--underlined`]: isUnderlined,
  });

  return (
    <span className={className}>{children}</span>
  );
};

FormGroupMessage.defaultProps = {
  isError: false,
};


export default FormGroupMessage;
