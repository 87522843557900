const PRODUCTION = 'https://audio-english.ru';
const DEVELOPMENT = 'http://test.audio-english.ru';


const API_HOST = PRODUCTION;

const toForm = (data = {}) => {
  const form = new FormData();
  Object.entries(data).forEach(({ 0: key, 1: value }) => form.append(key, value));
  return form;
};

const defaultFetchParams = {
  method: 'POST',
  credentials: 'same-origin',
  redirect: 'error',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
  },
};

export const register = ({ body }) => fetch(`${API_HOST}/users/free.html`, {
  method: 'POST',
  body,
  redirect: 'manual',
})
.then(response => {
  if (response.ok) {
    return response.json();
  }

  throw response;
})

const login = ({ body }) => fetch(`${API_HOST}/users/`, {
  method: 'POST',
  credentials: 'same-origin',
  redirect: 'manual',
  mode: 'cors',
  body,
})
.then((response) => {
  return response.json()
    .then((json) => {
    if (response.ok) {
      return {
        response,
        data: json,
      };
    }

    return {
      response,
      error: json,
    };
  });
})

const restorePasswordCode = ({ body }) => fetch(`${API_HOST}/users/restore-password-code/`, {
  ...defaultFetchParams,
  body,
})
.then(response => {
  if (response.ok) {
    return response.json();
  }

  throw response;
});

const checkRestorePasswordCode = ({ body }) => fetch(`${API_HOST}`);

const validateCode = ({ body }) => fetch(`${API_HOST}/users/validate-code/`, {
  method: 'POST',
  credentials: 'same-origin',
  body,
  redirect: 'manual',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
  },
})
.then(response => {
  if (response.ok) {
    return response;
  }

  throw response;
})

const changePassword = ({ body }) => fetch(`${API_HOST}/users/restore-password/`, {
  method: 'POST',
  credentials: 'same-origin',
  body,
  redirect: 'error',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
  },
})
.then(response => {
  if (response.ok) {
    return response.json();
  }

  throw response;
});


const registrationMapper = ({ email, password }) => {
  const data = {
    action: 'regfree',
    email,
    password,
  };

  const form = new FormData();
  Object.entries(data).forEach((el) => form.append(el[0], el[1]));

  return form;
};

export const registrationHandler = ({ email, password }) => register({ body: registrationMapper({ email, password }) });

const loginMapper = ({ email, password }) => {
  const data = {
    url: '/',
    action: 'login',
    login: email,
    password,
  };

  const form = new FormData();
  Object.entries(data).forEach(el => form.append(el[0], el[1]));

  return form;
};

export const loginHandler = ({ login: email, password }) => login({ body: loginMapper({ email, password }) });


const changePasswordMapper = ({ email, code, newPassword }) => {
  const data = {
    email,
    code,
    new_password: newPassword,
  };

  return toForm(data);
};

export const changePasswordHandler = ({ email, code, newPassword }) => changePassword({ body: changePasswordMapper({ email, code, newPassword }) });


const validateCodeDataMapper = ({ email, code }) => {
  const data = {
    email,
    code,
  };

  return toForm(data);
}

export const validateCodeHandler = ({ email, code }) => validateCode({ body: validateCodeDataMapper({ email, code })});


const restorePasswordCodeDataMapper = ({ email }) => {
  const data = {
    email,
  };

  return toForm(data);
};

export const restorePasswordCodeHandler = ({ email }) => restorePasswordCode({ body: restorePasswordCodeDataMapper({ email }) });

const checkRestorePasswordCodeDataMapper = ({ email, code }) => {
  const data = {
    email,
    code,
  };

  return toForm(data);
}

export const checkRestorePasswordCodeHandler = ({ email, code }) => checkRestorePasswordCode({ body: checkRestorePasswordCodeDataMapper({ email, code }) });
