import React from 'react';
import classNames from 'classnames';

import styles from './button.module.css';


interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    children,
    type = 'button',
    primary,
    fullWidth,
      ...rest
  } = props;

  const className = classNames(styles.buttonBase, styles.button, {
    [styles.buttonPrimary]: primary,
    [styles.fullWidth]: fullWidth,
  });

  return (
    <button
        type={type}
        className={className}
        {...rest}
    >
      <span className={styles.buttonLabel}>
        {children}
      </span>
    </button>
  );
};


export default Button;
