const get = (key = '') => {
    if (!key) return null;

    const resultString = global.localStorage.getItem(key);
    return JSON.parse(resultString);
}

const set = (key = '', val) => {
    if (!key) return null;

    global.localStorage.setItem(key, val);
};

const clear = () => global.localStorage.clear();


export default { get, set, clear };
