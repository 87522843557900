import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// import Button from '../../components/Button';
import { Button } from '../../components/Button/index.tsx';
import { Loader } from '../../components/Loader';
import { Typography } from "../../components/Typography";
import InputGroup from "../../components/InputGroup";

import { validEmail } from '../../utils/validation';

import { restorePasswordCodeHandler, validateCodeHandler } from '../../api';
import { sendEvent } from '../../api/analytics';
import FormGroupMessage from "../../components/FormGroup/FormGroupMessage";


const ERRORS = {
  NO_USER: 'Такой e-mail не был зарегистрирован',
	EMPTY_CODE: 'Пожалуйста, введите код',
	WRONG_CODE: 'Неверный код',
};

const RestorePassword = ({ handleChangeView }) => {
  const [restoreCode, setRestoreCode] = useState('');
  const changeRestoreCode = useCallback(({ target: { value } }) => setRestoreCode(value), []);

  const [codeSent, setCodeSent] = useState(false);

  const [email, setEmail] = useState('');
  const changeEmail = useCallback(({ target: { value } }) => setEmail(value), []);

  const [emailDisabled, setEmailDisabled] = useState(false);

  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);
  const startLoading = useCallback(() => setLoading(true), []);
  const endLoading = useCallback(() => setLoading(false), []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    setError('');

    if (codeSent) {
      if (!restoreCode) {
        return setError(ERRORS.EMPTY_CODE);
      }

      startLoading();
      return validateCodeHandler({ email, code: restoreCode })
      .then(() => {
        handleChangeView({ email, restoreCode });

        sendEvent({
          eventCategory: 'password_recovery',
          eventAction: 'code_correct',
          eventLabel: email,
        });
      })
      .catch(() => {
        setError('Неправильный код');

        sendEvent({
          eventCategory: 'password_recovery',
          eventAction: 'code_incorrect',
          eventLabel: email,
        });
      })
      .finally(() => endLoading());
    } else {
      if (!validEmail(email)) {
        return setError('Введите ваш e-mail');
      }

      startLoading();

      sendEvent({
        eventCategory: 'password_recovery',
        eventAction: 'not_registered',
        eventLabel: email,
      });

      restorePasswordCodeHandler({ email })
      .then(() => {
        setCodeSent(true);
        setEmailDisabled(true);

        sendEvent({
          eventCategory: 'password_recovery',
          eventAction: 'code_sent',
          eventLabel: email,
        });
      })
      .catch(() => {
        setError(ERRORS.NO_USER);

        sendEvent({
          eventCategory: 'password_recovery',
          eventAction: 'invalid_email',
        });
      })
      .finally(() => endLoading());
    }
  }, [codeSent, restoreCode, email]);

  return (
    <Fragment>
      <Typography>
        Восстановление пароля
      </Typography>
      <form
        className="aen-form"
        onSubmit={handleSubmit}
      >
        {codeSent && (
            <>
          <p className="aen-form__title">Мы отправили письмо с кодом на ваш e-mail</p>
              <div className="aen-formgroup__row">
                <InputGroup
                    placeholder="Код подтверждения"
                    value={restoreCode}
                    onChange={changeRestoreCode}
                />
              </div>
          </>
        )}
          {!codeSent && (
              <div className="aen-formgroup__row">
            <InputGroup
            placeholder="E-mail"
            value={email}
            onChange={changeEmail}
            disabled={emailDisabled}
            />
            </div>
          )}
          {error && (
            <div className="aen-formgroup__row">
              <FormGroupMessage isError>{error}</FormGroupMessage>
            </div>
          )}
        <div className="aen-modal__actions">
          <Button
            primary
            fullWidth
            type="submit"
          >
            Продолжить
          </Button>
        </div>
        {loading && <Loader />}
      </form>
    </Fragment>
  );
};

RestorePassword.propTypes = {
  handleChangeView: PropTypes.func.isRequired,
};


export default RestorePassword;
