import React from 'react';
import { connect } from 'react-redux';
// @ts-ignore
import { pushModalWindow } from 'redux-promising-modals';

import classes from './index.module.css';

interface ButtonProps {
  onClick: (params: { mode: string; }) => void;
}

const AuthorizationButton: React.FC<ButtonProps> = ({ onClick }) => {
  return (
    <>
      <button
        className={classes.button}
        onClick={() => onClick({ mode: 'REGISTRATION' })}
      >
        Регистрация
      </button>
      <button
        className={classes.button}
        onClick={() => onClick({ mode: 'LOGIN' })}
      >
        Войти
      </button>
    </>
  );
};


const mapDispatchToProps = {
  onClick: (props: { mode: string; }) => pushModalWindow('AuthModal', props),
};

export default connect(null, mapDispatchToProps)(AuthorizationButton);
