import React, { useState, memo } from 'react';
import classNames from 'classnames';

import styles from './input-group.module.css';


export interface InputGroupProps extends React.HTMLProps<HTMLInputElement> {
  value: string;
  onChange: (event: any) => void;
  endAdornment?: React.ReactNode;
  isError?: boolean;
  isLast?: boolean;
}

const InputGroup: React.FC<InputGroupProps> = ({
                                                 value,
                                                 onChange,
                                                 endAdornment,
                                                 isLast,
  isError,
                                                 ...restProps}) => {
  const [isFocused, setIsFocused] = useState(false);

  const className = classNames(styles.formControl, {
    [styles.formControl_focused]: isFocused,
    [styles.formControl_error]: isError,
  });

  return (
    <div className={className}>
      <div className={styles.inputRoot}>
        <input
          className={styles.inputBase}
          value={value}
          onChange={onChange}
          {...restProps}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {endAdornment && endAdornment}
      </div>
    </div>
  );
};


export default memo(InputGroup);
