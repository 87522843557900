import { combineReducers } from 'redux';
import { modalsReducer } from 'redux-promising-modals';

import entities from './entities.reducer';


const rootReducer = combineReducers({
  entities,
  modals: modalsReducer,
});


export default rootReducer;
