import React, { useState, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

import { Button } from '../components/Button/index.tsx';
import { Loader } from '../components/Loader';

import InputGroup from "../components/InputGroup/InputGroup";
import PasswordInput from "../components/PasswordInput";
import { FormHelperText } from '../components/FormHelperText';

import { validEmail } from '../utils/validation';


const serializeToForm = (data = {}) => {
  const form = new FormData();
  Object.entries(data).forEach(({ 0: key, 1: value }) => form.append(key, value));
  return form;
};

const PRODUCTION = 'https://audio-english.ru';
const DEVELOPMENT = 'http://test.audio-english.ru';


const API_HOST = PRODUCTION;

const defaultFetchParams = {
  redirect: 'manual',
};

export const registerAPI = ({ body }) => fetch(`${API_HOST}/users/free.html`, {
  ...defaultFetchParams,
  method: 'POST',
  body: serializeToForm({...body, action: 'regfree'}),
})
.then(response => {
  const contentType = response.headers.get('Content-Type');
  const isJsonResponse = contentType.includes('application/json');

  if (!isJsonResponse) {
    throw response;
  }

  return response.json()
    .then(json => {
      if (response.ok) {
      return {
        response,
        data: json,
      };
    }

      if (typeof json.status !== 'string') {
        throw new Error('API mismatch');
      }

    return {
      response,
      error: json,
    };
  });
})

const validPassword = str => str.length > 5;

const Registration = ({ goToLogin }) => {
  const [formSubmit, setFormSubmit] = useState({
    loading: false,
    error: null,
    data: null,
  });

  useEffect(() => {
    const { data } = formSubmit;

    if (data === null) {
      return;
    }

    window.location.reload();
  }, [formSubmit.data]);

  const startLoading = useCallback(() => setFormSubmit(state => ({
    ...state,
    loading: true,
  })), []);
  const endLoading = useCallback((result) => setFormSubmit(state => ({
    ...state,
    ...result,
    loading: false,
  })), []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate: values => {
      const errors = {};

      if (!values.email) {
        errors.email = 'Пожалуйста, введите эл. адрес';
      } else if (!validEmail(values.email)) {
        errors.email = 'Пожалуйста, введите корректный эл. адрес';
      }

      if (!values.password) {
        errors.password = 'Пожалуйста введите пароль';
      } else if (!validPassword(values.password)) {
        errors.password = 'Пароль должен быть больше пяти символов';
      }

      return errors;
    },
    onSubmit: values => {
      const {email, password} = values;

      startLoading();

      registerAPI({ body: { email, password } })
      .then(endLoading)
      .catch(() => endLoading({ error: 1 }));
    },
  });

  const renderFieldError = (fieldName) => {
    const { errors, touched } = formik;

    if (errors[fieldName] && touched[fieldName]) {
      return (
          <FormHelperText isError>
            {errors[fieldName]}
          </FormHelperText>
      );
    }

    return null;
  }

  const renderSubmitError = () => {
    const { error } = formSubmit;

    if (!error) {
      return null;
    }

    return (
      <div className="aen-formgroup__row">
        <FormHelperText isError>{(() => {
          const { error: { status } } = formSubmit;

          if (status === '10001') {
            return (
              <>
                Пользователь с таким эл. адресом уже зарегистрирован,{' '}
                <button
                  type="button"
                  style={{
                    border: 'none',
                    font: 'inherit',
                    fontWeight: 'normal',
                    color: 'black',
                    textDecoration: 'underline',
                    margin: '0',
                    padding: '0',
                    outline: 'none',
                    background: 'transparent',
                    cursor: 'pointer',
                  }}
                  onClick={goToLogin}
                >
                  войти
                </button>
              </>
            );
          }

          return 'Что-то пошло не так. Попробуйте позже.';
        })()}</FormHelperText>
      </div>
    );
  };

  return (
    <form
      className="aen-form"
      onSubmit={formik.handleSubmit}
    >
      {renderSubmitError()}
      <div className="aen-formgroup__row">
      <InputGroup
        name="email"
        placeholder="Email"
        value={formik.values.email}
        onChange={formik.handleChange}
        isError={formik.errors.email && formik.touched.email}
      />
        {renderFieldError('email')}
      </div>
      <div className="aen-formgroup__row">
      <PasswordInput
        name="password"
        placeholder="Придумайте пароль"
        value={formik.values.password}
        onChange={formik.handleChange}
        isError={formik.errors.password && formik.touched.password}
      />
        {renderFieldError('password')}
      </div>
      <div className="aen-formgroup__row">
        <p className="aen-agreement__text">
          Регистрируясь на audio-english.ru, вы соглашаетесь с <a target="_blank" href="/polzovatelskoe_soglashenie.html" className="aen-link">пользовательским соглашением</a>
        </p>
      </div>
      {renderFieldError('tosAgreed')}
      <div className="aen-modal__actions">
        <Button
          primary
          fullWidth
          type="submit"
        >
          Продолжить
        </Button>
      </div>
      {formSubmit.loading && <Loader />}
    </form>
  );
};

Registration.propTypes = {
  goToLogin: PropTypes.func.isRequired,
};


export default Registration;
