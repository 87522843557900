import 'core-js/es/array';
import 'core-js/es/date';
import 'core-js/es/function';
import 'core-js/es/map';
import 'core-js/es/math';
import 'core-js/es/number';
import 'core-js/es/object';
import 'core-js/es/parse-float';
import 'core-js/es/parse-int';
import 'core-js/es/regexp';
import 'core-js/es/set';
import 'core-js/es/string';
import 'core-js/es/symbol';
import 'core-js/es/weak-map';
import 'core-js/es/promise';

import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// @ts-ignore
import { pushModalWindow } from 'redux-promising-modals';

import createStore from './store';
import reducer from './reducers';

import AuthorizationButton from "./components/AuthorizationButton";
import { Authorization } from './Modals';
import App from './App';

const nodeRoots = {
  authModal: 'react-root-login',
  glopartPaymentSuccess: 'react-root-glopart-payment-success',
};

const store = createStore(reducer);

const initAuthModal = (nodeId: string, afterMount: () => void) => {
  const node = window.document.getElementById(nodeId);

  if (node === null) {
    return;
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AuthorizationButton />
        <Authorization />
      </Provider>
    </React.StrictMode>,
    node,
    afterMount,
  );
};

const afterModalMount = () => {
  const accessButton = document.getElementById('get-free-access-button');

  if (accessButton === null) {
    return;
  }

  // href="https://sso.teachable.com/secure/566559/users/sign_up?flow_school_id=566559"

  accessButton.addEventListener('click', () => {
    store.dispatch(pushModalWindow('AuthModal', { mode: 'REGISTRATION' }));
  });
};

initAuthModal(nodeRoots.authModal, afterModalMount);

const initGlopart = (nodeId: string) => {
  const node = window.document.getElementById(nodeId);

  if (node === null) {
    return;
  }

  node.style.display = 'flex';
  node.style.flex = '1 0 auto';

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App/>
      </Provider>
    </React.StrictMode>,
    node,
  );
};

initGlopart(nodeRoots.glopartPaymentSuccess);
