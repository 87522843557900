import React from 'react';

import { Check } from "../../svg";
import './checkbox.css';


interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxDefaultProps: Partial<CheckboxProps> = {
  checked: false,
};

export const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, ...inputProps }) => {
  return (
    <span className="aen-checkbox-root">
      <span className="aen-checkbox">
        <input
          type="checkbox"
          className="aen-checkbox__input"
          onChange={onChange}
          {...inputProps}
        />
        <span
          className={`aen-checkbox__icon${checked ? ' aen-checkbox__icon--checked' : ''}`}
        />
      </span>
    </span>
  );
};

Checkbox.defaultProps = CheckboxDefaultProps;
