import React from 'react';

import './typography.css';


type variant = 'h2' | 'subtitle2';
type element = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

interface TypographyProps {
  variant?: variant;
  element?: element,
  children: string;
}

const variantsMap = {
  'h2': 'aen-h2',
  'subtitle2': 'aen-typography--subtitle2',
};

const variantToClass = (variant: variant) => variantsMap[variant];

const elementsMap = {
  'h2': 'h2',
  'subtitle2': 'h6',
};

const variantToElement = (variant: variant) => elementsMap[variant];

export const Typography: React.FC<TypographyProps> = ({
                                                        variant = 'h2',
  element ,
                                                        children,
}) => {
  const elementType = variantToElement(variant) || element || 'span';
  const className = `aen-typography ${variantToClass(variant)}`;

  return React.createElement(
    elementType,
    {
      className,
    },
    children);
};
