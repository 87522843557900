import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../components/Button';
import { Loader } from '../../components/Loader';
import PasswordInput from "../../components/PasswordInput";

import { changePasswordHandler, loginHandler } from '../../api';
import { sendEvent, setUserId } from '../../api/analytics';

import localStorage from '../../localstorage';
import {Typography} from "../../components/Typography";
import FormGroupMessage from "../../components/FormGroup/FormGroupMessage";


const ERRORS = {
    EMPTY_PASSWORD: 'Введите новый пароль',
    EMPTY_REPEAT_PASSWORD: 'Повторите новый пароль',
    PASSWORDS_DONT_MATCH: 'Пароли не совпадают',
};

const ChangePassword = ({ email, restoreCode }) => {
  const [password, setPassword] = useState('');
  const changePassword = useCallback(({ target: { value } }) => setPassword(value), []);

  const [newPassword, setNewPassword] = useState('');
  const changeNewPassword = useCallback(({ target: { value } }) => setNewPassword(value), []);

  const [error, setError] = useState('');

  const [loading, setLoading] = useState(false);
  const startLoading = useCallback(() => setLoading(true), []);
  const endLoading = useCallback(() => setLoading(false), []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    setError('')

    if (!password) {
        return setError(ERRORS.EMPTY_PASSWORD);
    }

    if (!newPassword) {
        return setError(ERRORS.EMPTY_REPEAT_PASSWORD);
    }

    if (password !== newPassword) {
      sendEvent({
        eventCategory: 'password_recovery',
        eventAction: 'passwords_mismatch',
      });
      
      return setError(ERRORS.PASSWORDS_DONT_MATCH);
    }

    startLoading();
    changePasswordHandler({ email, code: restoreCode, newPassword })
    .then(() => {
      sendEvent({
        eventCategory: 'password_recovery',
        eventAction: 'password_changed',
      });

      return loginHandler({ login: email, password: newPassword })
      .then(({ data }) => {
        const { id: userId } = data;
  
        localStorage.set('userId', userId);
        setUserId(userId);
  
        return sendEvent({
          eventCategory: 'login',
          eventAction: 'login_success',
          eventLabel: email,
        })
        .finally(() => global.location.assign('/'));
      })
    }, () => {
      setError('Неправильный код');
    })
    .finally(endLoading);
  }, [email, restoreCode, password, newPassword]);

  const disabled = loading || !password || !newPassword;

  const onIconClick = useCallback(() => {
    sendEvent({
      eventCategory: 'password_recovery',
      eventAction: 'display_password_clicked',
    });
  }, []);

  return (
        <>
          <Typography>
            Восстановление пароля
          </Typography>
          <form
            className="aen-login"
            onSubmit={handleSubmit}
          >
            <div className="aen-modal__content">
            <div className="aen-formgroup">
              <div className="aen-input-item">
                <PasswordInput
                  placeholder="Новый пароль"
                  value={password}
                  onChange={changePassword}
                />
              </div>
              <div className="aen-input-item">
                <PasswordInput
                  placeholder="Повторите пароль"
                  value={newPassword}
                  onChange={changeNewPassword}
                />
              </div>
              {error && (
                <div className="aen-formgroup__row">
                  <FormGroupMessage isError>{error}</FormGroupMessage>
                </div>
              )}
            </div>
            <div className="aen-modal__actions">
              <Button
                primary
                onClick={handleSubmit}
                disabled={disabled}
              >
                {loading ? <Loader /> : 'Продолжить'}
              </Button>
            </div>
            </div>
          </form>
      </>
  );
};

ChangePassword.propTypes = {
    email: PropTypes.string.isRequired,
    restoreCode: PropTypes.string.isRequired,
};


export default ChangePassword;
