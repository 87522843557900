import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import logger from 'redux-logger';


const isDev = process.env.NODE_ENV === 'development';

const getComposeEnhancers = () => {
    if (typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
        return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
    }

    return compose;
};

const configureStore = (rootReducer) => {
    const middlewares = [thunk];

    if (isDev) {
        middlewares.push(logger);
    }

    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    return createStore(rootReducer, getComposeEnhancers()(...enhancers));
};


export default configureStore;
