import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs, { ParsedQs } from 'qs';

import { GlopartOrder } from "../../models/glopart";
import * as glopartService from '../../services/api/glopart';

import { Loader } from '../../components/Loader';
import { OrderPanel } from "./OrderInvalid";

interface response {
  statusCode: number;
  data: any;
}

interface error {
  statusCode: number;
  errors: any;
}

function useQuery() {
    const search = useLocation().search;

    return useMemo(() => {
        const queryString = search.startsWith('?') ? search.slice(1) : search;

        return qs.parse(queryString);
    }, [search]);
}

const extractOrderFromQuery = (query: ParsedQs): GlopartOrder => {
    const extracted = {
        id: query.order_id,
        customerEmail: query.customer_email,
        customerPhone: query.customer_phone,
        customerName: query.customer_name,
        pincode: query.pincode,
        signature: query.signature,
    };

    const ensuredKeys = Object.entries(extracted).reduce((acc, [key, value]) => {
      // @ts-ignore
      acc[key] = typeof value === 'string' ? value : '';
      return acc;
    }, {} as GlopartOrder);

    return ensuredKeys;
};

interface ProviderOrderState {
  isLoading: boolean;
  glopartOrder: null | GlopartOrder,
  error: null | any;
}

type RenderProps = (isOrderValid: boolean, orderData: ProviderOrderState) => JSX.Element;

interface GlopartOrderProviderProps {
  render: RenderProps;
}

const GlopartOrderProvider: React.FC<GlopartOrderProviderProps> = ({ render }) => {
  useLayoutEffect(() => {
    // Monkey patching layout
    const node = window.document.getElementById('main');
    if (node === null) {
      return;
    }

    const styles = {
      display: 'flex',
      flexDirection: 'column',
    };

    Object.entries(styles).forEach(([key, val]) => {
      // @ts-ignore
      node.style[key] = val;
    });
  }, []);

    const query = useQuery();

    const usersOrder = useMemo(() => extractOrderFromQuery(query), [query]);
    const isValid = useMemo(() => {
    let errors = 0;

    if (usersOrder.id.length < 1) {
      ++errors;
    }

    if (usersOrder.signature.length < 1) {
      ++errors;
    }

    return errors < 1;
  }, [usersOrder]);

    const [state, setState] = useState<ProviderOrderState>({
      isLoading: false,
      glopartOrder: null,
      error: null,
    });
    const startLoading = () => setState((prev) => ({
      ...prev,
      loading: true,
    }));
    const endLoading = (({ response, error }: any) => {
      if (error) {
        setState(prev => ({
          ...prev,
          error,
          loading: false,
        }));
      }

      const { entities: { glopartOrders }, result } = response;
      const glopartOrder = glopartOrders[result];

      setState(prev => ({
        ...prev,
        glopartOrder,
        loading: false,
      }));
    });

    React.useEffect(() => {
    if (!isValid) {
      return;
    }

    const run = async () => {
      try {
        startLoading();

        const findResult = await glopartService.getById({ body: { id: usersOrder.id } });

        console.log('response', findResult);

        if (findResult.response !== null) {
          return endLoading({ response: findResult.response });
        }

        if (findResult.error !== null && findResult.error.statusCode !== 404) {
          return endLoading({ error: findResult.error });
        }

        const createResult = await glopartService.create({ body: usersOrder });

        if (createResult.response) {
          return endLoading({ response: createResult.response });
        }

        endLoading({ error: createResult.error });
      } catch (error) {
        endLoading({ error });
      }
    };

    run();
    }, [usersOrder]);

    return render(isValid, state);
};

export const GlopartPaymentSuccess = () => {
  return (
    <GlopartOrderProvider
      render={(orderValid, { isLoading, glopartOrder, error }) => {
        if (!orderValid) {
          return <div>Данные о заказе не верны, пожалуйста обратитесь в техподдержку</div>;
        }

        if (isLoading) {
          return <Loader />;
        }

        if (error) {
          return <div>Что-то пошло не так</div>
        }

        if (glopartOrder) {
          return <OrderPanel glopartOrder={glopartOrder} />
        }

        return <Loader />;
      }}
    />
    );
};
