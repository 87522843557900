import React, { useState, useCallback } from 'react';

import RestorePasswordCode from './RestorePasswordCode';
import ChangePassword from './ChangePassword';


const PASSWORD_CODE = 'PASSWORD_CODE';
const PASSWORD_CHANGE = 'PASSWORD_CHANGE';

const RestorePassword = () => {
    const [email, setEmail] = useState('');
    const [restoreCode, setRestoreCode] = useState('');

    const [view, setView] = useState(PASSWORD_CODE);
    const changeView = useCallback(({ email, restoreCode }) => {
        setView(view === PASSWORD_CODE ? PASSWORD_CHANGE : PASSWORD_CODE);
        setEmail(email);
        setRestoreCode(restoreCode);
    }, [view]);

    

    if (view === PASSWORD_CODE) return <RestorePasswordCode handleChangeView={changeView} />;

    return <ChangePassword handleChangeView={changeView} email={email} restoreCode={restoreCode} />;
};


export default RestorePassword;
