const delay = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms));

const mockGA = () => {
    window.ga = (...args) => {
        console.log('GA event: ', ...args);

        const last = args.slice(-1).pop();

        return Promise.resolve()
          .then(() => {
              last.hitCallback && last.hitCallback();
          });
    }
};

const isDev = process.env.NODE_ENV === 'development';

if (isDev) {
    mockGA();
}

export const sendEvent = ({ ...params } = {}) => new Promise((resolve, reject) => {
    window.ga('send', {
        ...params,
        hitType: 'event',
        hitCallback: resolve,
        hitCallbackFail: reject,
    });
});

export const setUserId = (userId) => {
    window.ga('set', 'userId', userId);
}
