import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { GlopartPaymentSuccess } from "./pages/GlopartPaymentSuccess";


const App = () => {
  return (
    <Router>
      <Switch>
          <Route path="/glopart-payment-success.html">
              <GlopartPaymentSuccess />
          </Route>
          <Route path="/glopart-payment-success">
              <GlopartPaymentSuccess />
          </Route>
      </Switch>
    </Router>
  );
};


export default App;
