import React, { useState, useCallback } from 'react';

import InputGroup, { InputGroupProps } from "./InputGroup/InputGroup";
import Adornment from "./InputGroup/Adornment";
import { EyeOpened, EyeClosed } from "../svg";


const PasswordInput: React.FC<InputGroupProps> = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisible = useCallback(() => setIsVisible(state => !state), []);

  const type = isVisible ? 'text' : 'password';
  const Icon = isVisible ? EyeOpened : EyeClosed;

  return (
    <InputGroup
      {...props}
      type={type}
      endAdornment={(
        <Adornment isRight>
          <button
            className="MuiButtonBase-root MuiIconButton-root"
            tabIndex={0}
            type="button"
            aria-label="toggle password visibility"
            style={{
              backgroundColor: 'transparent',
              outline: 'none',
              border: 0,
              cursor: 'pointer',
              padding: '8px',
              color: '#0000008a',
            }}
            onClick={toggleVisible}
          >
            <span className="aen-icon-button-label">
              <Icon
                className="a-svg-icon"
                style={{
                  fontSize: '16px',
                }}
              />
            </span>
          </button>
        </Adornment>
      )}
    />
  );
};


export default PasswordInput;
