import React, { useRef } from 'react';
import classNames from 'classnames';


interface TabProps {
  text: string;
  isActive?: boolean;
  onClick: () => void;
}

export const Tab = React.forwardRef<any, TabProps>(({ text, isActive, onClick }, ref) => {
  const className = classNames('aen-tab', {
    'aen--active': isActive,
  });

  return (
    <button
      className={className}
      onClick={onClick}
      ref={ref}
    >
      <span className="aen-tab__text">
        {text}
      </span>
    </button>
  );
});

interface TabsProps {
  value: number;
  onChange: (index: number) => void;
  children: any;
}

export const Tabs: React.FC<TabsProps> = ({ value, onChange, children }) => {
  const childrenRefs = useRef(Array.from({ length: React.Children.count(children) }, () => React.createRef()));
  const [indicator, setIndicator] = React.useState({left: 0, width: 0});
  const handleChangeIndicator = ({ offset, width }: { offset: number, width: number }) => {
    setIndicator({ left: offset, width });
  };
  // const childrenRefs = useRef([]);
  //
  React.useLayoutEffect(() => {
    // React.Children.map(children, console.log);
    const activeChildIndex = React.Children.toArray(children).find((_, index) => index === value);

    if (activeChildIndex === undefined) {
      return;
    }

    // @ts-ignore
    const childRef: HTMLButtonElement = childrenRefs?.current[activeChildIndex.props.index]?.current;

    if (!childRef) {
      return;
    }

    // @ts-ignore
    handleChangeIndicator({ offset: childRef?.offsetLeft, width: childRef?.offsetWidth });

    // console.log('activeChildIndex', childRef?.offsetLeft);


  }, [children]);

  return (
    <div className="aen-tabs__scroller">
      <div className="aen-tabs__container">
        {React.Children.map(children, (child, index) => React.cloneElement(child, {
          ref: childrenRefs.current[index],
          isActive: index === value,
          onClick: () => {
            child.props.onClick && child.props.onClick();
            onChange(index);
            },
        }))}
      </div>
      <span className="aen-tabs__indicator" style={indicator} />
    </div>
  );
};
