import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { popModalWindow } from 'redux-promising-modals';

import { Tabs, Tab } from "../components/Tabs";
import Button from '../components/Button';

import Registration from './Registration';
import Login from './Login';
import RestorePassword from './RestorePassword';

import { ArrowBack, Cross } from "../svg";

import { sendEvent } from '../api/analytics';

const REGISTRATION = 'REGISTRATION';
const LOGIN = 'LOGIN';

const AUTHORIZATION = 'AUTHORIZATION';
const RESTORE_PASSWORD = 'RESTORE_PASSWORD';

Modal.setAppElement('body');

const TABS = [LOGIN, REGISTRATION];

const Authorization = ({ mode, close }) => {
  const activeIndex = useMemo(() => TABS.indexOf(mode), [mode]);

  const [activeTab, setActiveTab] = useState(activeIndex);
  const handleChangeActiveTab = useCallback((index) => setActiveTab(index), []);

  const handleGoToLogin = useCallback(() => handleChangeActiveTab(0), []);

  const [viewMode, setViewMode] = useState(AUTHORIZATION);
  const handleChangeViewMode = useCallback((nextViewMode) => setViewMode(nextViewMode), []);

  const handleRestorePassword = useCallback(() => handleChangeViewMode(RESTORE_PASSWORD), []);


  // const [mode, setMode] = useState(LOGIN);
  //
  // const setRegistrationMode = useCallback(() => setMode(REGISTRATION), []);
  // const setLoginMode = useCallback(() => setMode(LOGIN), []);
  // const setRestorePasswordMode = useCallback(() => setMode(RESTORE_PASSWORD), []);

  const registrationClick = useCallback(() => {
    // setRegistrationMode();
    sendEvent({
      eventCategory: 'login',
      eventAction: 'sign_up_clicked',
    });
    // .then(() => global.location.assign('/price.html'));
  }, [null]);


  return (
    <Modal
      overlay
      isOpen
      overlayClassName="aen-modal__root aen-modal__overlay aen-modal__container"
      className="aen-modal"
      onRequestClose={close}
    >
      <>
        <div className="aen-modal__header">
          <button className="modal__close-button" onClick={close}>
          <Cross className="a-svg-icon" />
          </button>
        </div>
        {viewMode === AUTHORIZATION && (
          <div className="aen-modal__content">
          <Tabs value={activeTab} onChange={handleChangeActiveTab}>
            <Tab
              text="Вход"
              index={0}
              onClick={null}
            />
            <Tab
              text="Регистрация"
              index={1}
              onClick={null}
            />
          </Tabs>
          {activeTab === 1 && <Registration goToLogin={handleGoToLogin} />}
          {activeTab === 0 && <Login handleRestorePassword={handleRestorePassword} />}
        </div>
        )}
        {viewMode === RESTORE_PASSWORD && (
            <div className="aen-modal__content">
            <RestorePassword />
            </div>
            )}
      </>
      </Modal>
  );
};

Authorization.propTypes = {
  mode: PropTypes.string,
  close: PropTypes.func.isRequired,
};

Authorization.defaultProps = {
  mode: LOGIN,
};




const Container = ({ activeModal, activeModalProps, close, children }) => {
  if (activeModal === undefined) {
    return null;
  }

  return children(activeModalProps, close);
};

const mapStateToProps = state => ({
  activeModal: state.modals.types[0],
  activeModalProps: state.modals.props[0],
});

const mapDispatchToProps = (dispatch, { modalId }) => ({
  close: (result) => dispatch(popModalWindow(modalId, result)),
});

const ConnectedContainer = connect(mapStateToProps, mapDispatchToProps)(Container);

export default () => {
  return(
    <ConnectedContainer
      modalId="AuthModal"
    >
      {(props, close) => <Authorization mode={props.mode} close={close} />}
    </ConnectedContainer>
  );
};
