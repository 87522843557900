import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import styles from './form.module.css';

// import Button from '../components/Button';
import { Button } from '../components/Button/index.tsx';
import { Loader } from '../components/Loader';

import InputGroup from "../components/InputGroup";
import PasswordInput from "../components/PasswordInput";
import FormGroupMessage from "../components/FormGroup/FormGroupMessage";
import { FormHelperText } from '../components/FormHelperText';

import { loginHandler } from '../api';

import { sendEvent, setUserId } from '../api/analytics';

import localStorage from '../localstorage';


const LoginSchema = Yup.object().shape({
  login: Yup.string()
    .email('Пожалуйста, введите корректный эл. адрес')
    .required('Пожалуйста введите эл. адрес'),
  password: Yup.string()
    .required('Пожалуйста введите пароль'),
});

const Login = ({ handleRestorePassword }) => {
  useEffect(() => {
    sendEvent({ eventCategory: 'login', eventAction: 'popup_opened' });
  }, []);

  const [loginSubmit, setLoginSubmit] = useState({
    loading: false,
    data: null,
    error: null,
  });
  const startLoading = useCallback(() => setLoginSubmit(state => ({
    ...state,
    loading: true,
  })), []);
  const endLoading = useCallback((result) => setLoginSubmit(state => ({
    ...state,
    ...result,
    loading: false,
  })), []);

  const handleOnSubmit = (values) => {
    const { login, password } = values;

    startLoading();

    loginHandler({ login, password })
      .then(({ data, error }) => {
        if (error) {
          switch (error.status) {
                    case '10001': {
                      endLoading({ error: 'Неправильный логин' });
                      sendEvent({
                        eventCategory: 'login',
                        eventAction: 'invalid_email',
                        eventLabel: login,
                      });

                      break;
                    }
                    case '10002': {
                      endLoading({ error: 'Неправильный пароль' });
                      sendEvent({
                        eventCategory: 'login',
                        eventAction: 'login_failed',
                        eventLabel: login,
                      });

                      break;
                    }
                    default: {
                      endLoading({ error: 'Что-то пошло не так. попробуйте позже.' });
                      break;
                    }
                  }
        } else {
          const { id: userId } = data;

          localStorage.set('userId', userId);
          setUserId(userId);

          return sendEvent({
            eventCategory: 'login',
            eventAction: 'login_success',
            eventLabel: login,
          })
            .then(() => global.location.reload())
        }
      })
      .catch(() => endLoading({ error: 'Что-то пошло не так. попробуйте позже.' }));
  };

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: handleOnSubmit,
  });

  const renderFieldError = (fieldName) => {
    const { errors, touched } = formik;

    if (errors[fieldName] && touched[fieldName]) {
      return (
        <FormHelperText isError>
          {errors[fieldName]}
        </FormHelperText>
      );
    }

    return null;
  }

  const renderSubmitError = () => {
    const { error } = loginSubmit;

    if (!error) {
      return null;
    }

    return (
        <div className="aen-formgroup__row">
          <FormHelperText isError>{error}</FormHelperText>
        </div>
    );
  };

  return (
        <form
          className="aen-form"
          onSubmit={formik.handleSubmit}
        >
          {renderSubmitError()}
          <div className="aen-formgroup__row">
          <InputGroup
            name="login"
            type="text"
            placeholder="Email"
            value={formik.values.login}
            onChange={formik.handleChange}
            isError={formik.errors.login && formik.touched.login}
          />
          </div>
          {renderFieldError('login')}
          <div className="aen-formgroup__row">
          <PasswordInput
            name="password"
            placeholder="Пароль"
            value={formik.values.password}
            onChange={formik.handleChange}
            isError={formik.errors.password && formik.touched.password}
            isLast
          />
          </div>
          {renderFieldError('password')}
          <div className="aen-formgroup__row">
          <Button
            onClick={handleRestorePassword}
          >
            Забыли пароль?
          </Button>
          </div>
          <div className="aen-modal__actions">
            <Button
                primary
                fullWidth
                type="submit"
            >
              Продолжить
            </Button>
          </div>
          {loginSubmit.loading && <Loader />}
        </form>
  );
};

Login.propTypes = {
  handleRestorePassword: PropTypes.func.isRequired,
};


export default Login;
