import React from 'react';

import { LoadingCircle } from "../../svg";
import styles from './loader.module.css';


const Loader = () => (
  <div className={styles.loadingScreen}>
    <LoadingCircle className={styles.loadingScreenIcon} />
  </div>
);


export default Loader;
