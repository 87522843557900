import React, { useEffect, useState, useCallback, useMemo } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import { CheckCircleOutlined } from "../../svg";
import styles from "./index.module.css";
import {Typography} from "../../components/Typography";
import {Checkbox} from "../../components/Checkbox";
import {Button} from "../../components/Button";
import { Loader } from '../../components/Loader';
import { FormHelperText } from '../../components/FormHelperText';

import { GlopartOrder } from "../../models/glopart";
import { assignToUser } from "../../services/api/glopart";


const assignOrderSchema = Yup.object().shape({
  tosAgreed: Yup.boolean()
    .required('Вы не согласны с условиями использования сайта')
    .oneOf([true], 'Вы не согласны с условиями использования сайта'),
});

interface OrderPanelProps {
  glopartOrder: GlopartOrder;
}

interface Submit {
  loading: boolean;
  userId: null | string;
  error: null | any;
}

export const OrderPanel: React.FC<OrderPanelProps> = ({ glopartOrder }) => {
  const [submit, setSubmit] = useState<Submit>({
    loading: false,
    userId: null,
    error: null,
  });

  useEffect(() => {
    const { userId } = submit;

    if (userId === null) {
      return;
    }

    window.location.href = '/';
  }, [submit.userId]);

  const handleSubmit = useCallback((values) => {
    const assignDto = {
      order: {
        id: glopartOrder.id,
      },
    };

    setSubmit(state => ({
      ...state,
      loading: true,
    }));

    assignToUser({ body: assignDto })
      .then(({ response, error }) => {
        if (error !== null) {
          const errorEntry = error.errors[0];

          setSubmit(state => ({
            ...state,
            error: {
              type: 'apiError',
              ...errorEntry,
            },
            loading: false,
          }));
        }
        if (response !== null) {
          const userId = response.data.user.id;

          setSubmit(state => ({
            ...state,
            userId,
            error: null,
            loading: false,
          }));
        }
      })
      .catch((error) => {
        setSubmit(state => ({
          ...state,
          error: {
            type: 'exception',
            message: error.message,
          },
          loading: false,
        }));
      });
  }, [glopartOrder]);

  const formik = useFormik({
    initialValues: {
      tosAgreed: false,
    },
    validationSchema: assignOrderSchema,
    onSubmit: handleSubmit,
  });

  const renderSubmitError = () => {
    const { error } = submit;

    if (error === null) {
      return null;
    }

    const text = (() => {
      if (error.type === 'apiError') {
        if (error.code === 9001) {
          return 'Такой пользователь уже зарегистрирован';
        }
      }

      return 'Что-то пошло не так';
    })();

    return (
      <FormHelperText isError>
        {text}
      </FormHelperText>
    );
  };

  const renderFormError = () => {
    const { errors, touched } = formik;

    if (!(errors.tosAgreed && touched.tosAgreed)) {
      return null;
    }

    return (
      <FormHelperText isError>
        {errors.tosAgreed}
      </FormHelperText>
    );
  };

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      {renderSubmitError()}
      <div className={styles.icon}>
        <CheckCircleOutlined className={`a-svg-icon ${styles.successIcon}`} />
      </div>
      <Typography variant="h2">Спасибо за покупку!</Typography>
      <div className={styles.agreement}>
        <Checkbox
          name="tosAgreed"
          checked={formik.values.tosAgreed}
          onChange={formik.handleChange}
        />
        <Typography variant="subtitle2">
          Продолжая использовать audio-english.ru вы соглашаетесь с Пользовательским соглашением
        </Typography>
      </div>
      {renderFormError()}
      <Button
        type="submit"
        primary
        fullWidth
      >
        Продолжить
      </Button>
      {submit.loading && <Loader />}
    </form>
  );
};
