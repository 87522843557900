import camelize  from 'camelcase-keys';
import { normalize, Schema } from 'normalizr';

const { fetch } = window;

const apiHost = 'https://audio-english.ru';

const ensureLeadingSlash = (str: string) => str.startsWith('/') ? str : `/${str}`;
const createFullPath = (path: string) => `${apiHost}${ensureLeadingSlash(path)}`;

const request = (method: string) => (path: string, { body }: { body: any }, schema?: Schema) => {
  const options: RequestInit = {
    method,
    redirect: 'manual',
  };

  if (body != null) {
    options.body = JSON.stringify(body);
  }

  return fetch(createFullPath(path), options)
    .then((response) => response.json().then(json => ({ response, json })))
    .then(({ response, json }) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      // console.log('json', json);

      const camelized = camelize(json, { deep: true });

      console.log('camelized', camelized);

      if (schema) {
        const normalized = normalize(camelized.data, schema as any);

        console.log('normalized', normalized);

        return normalized;
      }

      return camelized;
      // return camelize(json);
    })
    .then(
      response => ({ response, error: null }),
      error => ({ response: null, error }),
    );
}

export const get = request('GET');
export const post = request('POST');
