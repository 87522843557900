import React from 'react';
import classNames from 'classnames';

import styles from './adornment.module.css';


interface AdornmentProps {
  isRight?: boolean,
}

const Adornment: React.FC<AdornmentProps> = ({ isRight, children }) => {
  const className = classNames(styles.adornmentRoot, {
    [styles.adornmentRight]: isRight,
  });

  return (
    <div className={className}>
      {children}
    </div>
  );
};


export default Adornment;
