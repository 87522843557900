import { schema } from 'normalizr';

import { post } from "./baseRequest";
import { GlopartOrder } from "../../models/glopart";

type getByIdDto = {
  id: string;
};

type assignToUserDto = {
  order: {
    id: string;
  };
};

const glopartOrdersSchema = new schema.Entity('glopartOrders');

export const assignToUser = ({ body }: { body: assignToUserDto }) => post('/users/glopart-register/', { body })
export const getById = ({ body }: { body: getByIdDto }) => post('/users/glopart-get/', { body }, glopartOrdersSchema);
export const create = ({ body }: { body: GlopartOrder }) => post('/users/glopart-create/', { body }, glopartOrdersSchema);