import React from 'react';
import classNames from 'classnames';

import styles from './form-helper-text.module.css';


const FormHelperText: React.FC<{isError: boolean;}> = ({ children, isError }) => {
    const className = classNames(styles.formHelperText, {
        [styles.aenError]: isError,
    });

    return (
        <p className={className}>{children}</p>
    );
};


export default FormHelperText;
